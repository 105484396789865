
























































































































import { defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import useString from '@/use/string'
import { AxiosInstance } from 'axios'
import useAttachment from '@/use/attachment'

export default defineComponent({
  setup(_, { root }) {
    const  { nl2br } = useString()
    const { downloadAttachment } = useAttachment({ root })

    const request = ref<null | { [key: string]: any }>(null)

    const state = reactive({
      loading: true,
      error: false
    })

    const fetch = () => {
      state.loading = true
      const apiInstance = root.$store.getters['api/getInstance'] as AxiosInstance

      apiInstance
        .get(`request/${root.$route.params.id}`)
        .then(({ data }) => {
          console.log(data.request)
          request.value = data.request
        })
        .catch(() => state.error = true)
        .finally(() => state.loading = false)
    }

    onMounted(fetch)

    const getRequestType = (type: string) => {
      switch (type) {
        case 'demand': return 'Zapotrzebowanie'
        default: return type
      }
    }
    
    return { request, state, getRequestType, nl2br, downloadAttachment }
  }
})
